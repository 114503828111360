import React from "react";
import { Text, TouchableOpacity, StyleSheet } from "react-native";

interface NavItemProps {
  label: string;
  color: string;
  onPress: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ label, color, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text style={[styles.navItem, { color }]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  navItem: {
    fontWeight: "bold",
    fontFamily: "Mali-Bold",
    fontSize: 15,
  },
});

export default NavItem;
