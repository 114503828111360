import React from "react";
import { Image, StyleSheet, View } from "react-native";

interface CampCardProps {
  id: number;
  src?: any;
  url?: string;
}

const CampCard: React.FC<CampCardProps> = ({ url, src, width }) => {
  const maxWidth = Math.min(width * 0.8, 500);
  const styles = createStyles(maxWidth);
  return (
    <View style={styles.card}>
      <Image
        style={styles.image}
        resizeMode="cover"
        source={
          src
            ? src
            : {
                uri: url,
              }
        }
      />
    </View>
  );
};

const createStyles = (width) =>
  StyleSheet.create({
    card: {
      height: 250,
      width: width,
      backgroundColor: "white",
      borderRadius: 10,
      margin: 10,
      padding: 5,
    },
    image: {
      width: undefined,
      height: undefined,
      flex: 1,
      borderRadius: 10,
    },
  });

export default CampCard;
