import React from "react";
import { StyleSheet, Text, View, Image, Dimensions } from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";

const GLORIA_IMG = require("../../assets/images/yo.JPG");
const GLORIA_TRANSPARENT_IMG = require("../../assets/images/yo-transparent.png");

const IMG_SIZE = 250;
const IMG_MULT = 1.4;

const ProfeGloria = () => {
  return (
    <View style={styles.profeGloriaContainer}>
      <View style={styles.imageOuterContainer}>
        <View style={styles.imageInnerContainer}>
          <Image source={GLORIA_IMG} style={styles.image} />
        </View>
      </View>
      <View style={styles.imageOverlayContainer}>
        <Image source={GLORIA_TRANSPARENT_IMG} style={styles.imageOverlay} />
        <h1>
          <Text style={styles.profeGloriaText}>
            Gloria Mitayne{"\n"}
            <Text style={styles.profeGloriaSubText}>
              Lic. Educacion Inicial{"\n"}
              Terapeuta del lenguaje y habla
            </Text>
          </Text>
        </h1>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  profeGloriaContainer: {
    paddingHorizontal: 60,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
  },
  imageOuterContainer: {
    padding: 10,
    backgroundColor: COLOR_PALETTE.lightYellow,
    borderRadius: 200,
  },
  imageInnerContainer: {
    width: IMG_SIZE * 1.1,
    height: IMG_SIZE * 1.1,
  },
  image: {
    flex: 1,
    borderRadius: 200,
    width: undefined,
    height: undefined,
    backgroundColor: "white",
  },
  imageOverlayContainer: {
    position: "absolute",
    width: IMG_SIZE * 1.2,
    height: IMG_SIZE * IMG_MULT * 1.2,
  },
  imageOverlay: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 40,
    bottom: 0,
    width: undefined,
    height: undefined,
  },
  profeGloriaText: {
    fontFamily: "Mali-Bold",
    fontWeight: "bold",
    color: COLOR_PALETTE.font,
    position: "absolute",
    bottom: -80,
    left: 0,
    right: 0,
    fontSize: 20,
    textAlign: "center",
  },
  profeGloriaSubText: {
    fontSize: 14,
    fontWeight: "normal",
    fontFamily: "Mali",
  },
});

export default ProfeGloria;
