import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR_PALETTE } from "../../../theme/colors";
import defaultValues from "../../../theme/values";
import { FontAwesome } from "@expo/vector-icons";

const CampInfo: React.FC = ({ mobileLayout }) => {
  const styles = createStyles(mobileLayout);
  return (
    <View style={styles.infoContainer}>
      <h1 style={styles.title}>Un campamento significativo para tus hijos</h1>
      <View
        style={{
          flexDirection: mobileLayout ? "column-reverse" : "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ padding: 20, maxWidth: mobileLayout ? "95%" : "45%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="calendar" color={COLOR_PALETTE.blue} size={18} />
            <h2>
              <Text style={styles.description}>
                Fecha: 03 Julio - 04 Agosto
              </Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="clock-o" color={COLOR_PALETTE.blue} size={18} />
            <h2>
              <Text style={styles.description}>Hora: 8:00am - 12:30pm</Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="map-pin" color={COLOR_PALETTE.blue} size={18} />{" "}
            <h2>
              <Text style={styles.description}>
                Lugar: Colegio San Judas Tadeo (Dirigido por Profe Gloria)
              </Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="money" color={COLOR_PALETTE.blue} size={18} />
            <h2>
              <Text style={styles.description}>
                Precio:{" "}
                <Text style={{ textDecorationLine: "line-through" }}>
                  RD$5,500
                </Text>{" "}
                RD$4,950 por semana
              </Text>
            </h2>
            <View
              style={{
                position: "absolute",
                right: mobileLayout ? 0 : -100,
                width: "80%",
                maxWidth: 200,
                top: mobileLayout ? 70 : 50,
                borderWidth: 5,
                borderRadius: 100,
                borderTopLeftRadius: 0,
                padding: 10,
                borderColor: COLOR_PALETTE.pink,
                backgroundColor: COLOR_PALETTE.lightPink,
              }}
            >
              <h2>
                <Text
                  style={[
                    styles.description,
                    {
                      color: "white",
                      textAlign: "center",
                      padding: 5,
                    },
                  ]}
                >
                  10% de descuento hasta el 31 de mayo
                </Text>
              </h2>
            </View>
          </View>
        </View>
        <View style={{ padding: 20, width: mobileLayout ? "95%" : "45%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="shower" color={COLOR_PALETTE.blue} size={18} />
            <h2>
              <Text style={styles.description}>Juegos de Agua</Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome
              name="paint-brush"
              color={COLOR_PALETTE.blue}
              size={18}
            />
            <h2>
              <Text style={styles.description}>Manualidades</Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome
              name="eyedropper"
              color={COLOR_PALETTE.blue}
              size={18}
            />
            <h2>
              <Text style={styles.description}>Experimentos</Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="futbol-o" color={COLOR_PALETTE.blue} size={18} />
            <Text style={styles.description}>Deportes</Text>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome
              name="birthday-cake"
              color={COLOR_PALETTE.blue}
              size={18}
            />
            <h2>
              <Text style={styles.description}>Fiestas</Text>
            </h2>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome
              name="binoculars"
              color={COLOR_PALETTE.blue}
              size={18}
            />
            <h2>
              <Text style={styles.description}>Visitas Sorpesas</Text>
            </h2>
          </View>
        </View>
      </View>
    </View>
  );
};

const createStyles = (mobileLayout) =>
  StyleSheet.create({
    infoContainer: {
      marginBottom: mobileLayout ? 120 : 70,
      marginTop: 60,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: 800,
      alignSelf: "center",
      padding: 20,
      margin: 10,
      borderRadius: 15,
      backgroundColor: "white",
      borderWidth: 5,
      borderColor: COLOR_PALETTE.blue,
    },
    title: {
      fontSize: mobileLayout
        ? defaultValues.fontSize.primary
        : defaultValues.fontSize.primary * 1.5,
      letterSpacing: 1,
      color: COLOR_PALETTE.blue,
      fontWeight: "bold",
      fontFamily: "Mouldy",
      margin: 10,
      marginBottom: 40,
    },
    description: {
      textAlign: "justify",
      fontSize: defaultValues.fontSize.body,
      color: COLOR_PALETTE.blue,
      fontWeight: "bold",
      fontFamily: "Mali-Bold",
      letterSpacing: 0,
      margin: 7,

      lineHeight: 30,
    },
    highlight: {
      color: COLOR_PALETTE.purple,
    },
  });

export default CampInfo;
