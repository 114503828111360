import React, { useState, useEffect, useMemo } from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";

const COLORS = [
  COLOR_PALETTE.orange,
  COLOR_PALETTE.pink,
  COLOR_PALETTE.purple,
  COLOR_PALETTE.blue,
  COLOR_PALETTE.yellow,
  COLOR_PALETTE.green,
];
const LETTERS = ["G", "L", "O", "R", "I", "A"];

const GloriaRandomizer = () => {
  const { width, height } = useWindowDimensions();
  const [positions, setPositions] = useState([]);
  const [rotations, setRotations] = useState([]);

  useEffect(() => {
    generateRandomPositionsAndRotations(width, height);
  }, [width, height]);

  const generateRandomPositionsAndRotations = (width, height) => {
    const newPositions = [];
    const newRotations = [];

    for (let i = 0; i < 20; i++) {
      let x, y, distance, isTooClose;
      do {
        x = Math.floor(Math.random() * width);
        y = Math.floor(Math.random() * height);
        isTooClose = false;

        for (const position of newPositions) {
          distance = Math.sqrt(
            Math.pow(x - position.left, 2) + Math.pow(y - position.top, 2)
          );
          if (distance < 100) {
            isTooClose = true;
            break;
          }
        }
      } while (isTooClose);

      const rotation = Math.floor(Math.random() * 61) - 30;

      newPositions.push({ left: x, top: y });
      newRotations.push(rotation);
    }

    setPositions(newPositions);
    setRotations(newRotations);
  };

  const letters = useMemo(() => {
    return positions.map((position, index) => {
      const color = COLORS[index % LETTERS.length];
      const letter = LETTERS[index % LETTERS.length];
      const rotation = rotations[index];
      return (
        <Letter
          key={index}
          position={position}
          rotation={rotation}
          color={color}
          letter={letter}
        />
      );
    });
  }, [positions, rotations]);

  return <View style={styles.container}>{letters}</View>;
};
interface LetterProps {
  position: { left: number; top: number };
  rotation: number;
  color: string;
  letter: string;
}

const Letter: React.FC<LetterProps> = React.memo(
  ({ position, rotation, color, letter }) => {
    return (
      <Text
        style={[
          styles.letter,
          position,
          { transform: [{ rotate: `${rotation}deg` }] },
          { color },
        ]}
      >
        {letter}
      </Text>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.3,
  },
  letter: {
    position: "absolute",
    fontSize: 45,
    fontWeight: "bold",
    fontFamily: "Mali-Bold",
    color: COLOR_PALETTE.lightYellow,
  },
});

export default GloriaRandomizer;
