import React from "react";
import { View, Image, TouchableOpacity, StyleSheet } from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";
import { LOGO } from "../../screens/sections/bio";
import NavItem from "./NavItem";
import defaultValues from "../../theme/values";

interface HeaderProps {
  scrollToElement: (section: string) => void;
}

export const NAV_HEIGHT = 70;

const Header: React.FC<HeaderProps> = ({ scrollToElement }) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <TouchableOpacity onPress={() => scrollToElement("bio")}>
          <Image source={LOGO} resizeMode="cover" style={styles.logo} />
        </TouchableOpacity>
        <View style={styles.navItemsContainer}>
          <NavItem
            label="Campamento"
            color={COLOR_PALETTE.blue}
            onPress={() => scrollToElement("camp")}
          />
          {false && (
            <>
              <NavItem
                label="Tutorias"
                color={COLOR_PALETTE.pink}
                onPress={() => scrollToElement("tut")}
              />
              <NavItem
                label="Cuenta Cuentos"
                color={COLOR_PALETTE.orange}
                onPress={() => scrollToElement("cuen")}
              />
              <NavItem
                label="Logopedia"
                color={COLOR_PALETTE.green}
                onPress={() => scrollToElement("log")}
              />
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: NAV_HEIGHT,

    backgroundColor: "white",

    borderBottomColor: "#ededed",

    borderBottomWidth: 0.5,
  },
  contentContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    maxWidth: defaultValues.maxWidth.primary,
    width: "100%",
    alignSelf: "center",
  },
  logo: {
    height: 50,
    width: 110,
  },
  navItemsContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});

export default Header;
