export const COLOR_PALETTE = {
  orange: "#FF914D",
  lightOrange: "#FFC09F",
  purple: "#A47EF4",
  lightPurple: "#C1A3FF",
  pink: "#FF66C5",
  lightPink: "#FFB2D9",
  blue: "#38B6FF",
  lightBlue: "#9AD1FF",
  green: "#7ED957",
  lightGreen: "#C3FDB8",
  yellow: "#FFEA40",
  lightYellow: "#fcfad5",
  background: "#fff",
  font: "#455a64",
};
