import React from "react";
import { StyleSheet, View } from "react-native";
import { COLOR_PALETTE } from "../../../theme/colors";

const CircleBackground: React.FC = () => {
  return (
    <View style={styles.circleContainer}>
      <View style={styles.smallCircle1} />
      <View style={styles.smallCircle2} />
      <View style={styles.mediumCircle} />
      <View style={styles.largeCircle} />
    </View>
  );
};

const styles = StyleSheet.create({
  circleContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 500,
    width: 500,
    borderRadius: 500,
    backgroundColor: COLOR_PALETTE.blue,
  },
  smallCircle1: {
    position: "absolute",
    top: -50,
    left: 20,
    height: 100,
    width: 100,
    borderRadius: 100,
    backgroundColor: COLOR_PALETTE.blue,
  },
  smallCircle2: {
    position: "absolute",
    top: 150,
    right: -50,
    height: 50,
    width: 50,
    borderRadius: 200,
    backgroundColor: COLOR_PALETTE.blue,
  },
  mediumCircle: {
    position: "absolute",
    top: -250,
    left: 125,
    height: 130,
    width: 130,
    borderRadius: 200,
    backgroundColor: COLOR_PALETTE.blue,
  },
  largeCircle: {
    position: "absolute",
    top: 150,
    right: -1000,
    height: 130,
    width: 130,
    borderRadius: 200,
    backgroundColor: COLOR_PALETTE.blue,
  },
});

export default CircleBackground;
