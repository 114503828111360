import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Pressable,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";

import { FontAwesome } from "@expo/vector-icons";
import { COLOR_PALETTE } from "../theme/colors";
import Bio from "./sections/bio";
import GloriaRandomizer from "../components/GloriaDecoration/GloriaDecoration";
import Tutorias from "./sections/tutorias";
import { useRef, useState } from "react";
import BookAnimation from "../components/Animations/BookAnimation";
import CuentaCuentos from "./sections/cuentos2";
import Logopedia from "./sections/logopedia";
import Contacto from "../components/Form/Contacto";
import CardboardBook from "../components/Book/Book";
import defaultValues from "../theme/values";
import Campamento from "./sections/campamento/campamento";

const GLORIA_IMG = require("../assets/images/yo.JPG");
const GLORIA_TRANSPARENT_IMG = require("../assets/images/yo-transparent.png");

const LOGO = require("../assets/images/logo.png");

const IMG_SIZE = 250;
const IMG_MULT = 1.4;

const pages = [
  "Welcome to the Cardboard Book!",
  "This is page 2.",
  "Another page, page 3.",
  "Page 4 is here.",
  "This is the last page, page 5.",
];

export default function Home({
  scrollViewRef,
  handleRef,
  width,
  height,
  mobileLayout,
}) {
  const [formVisible, setFormVisible] = useState(false);

  return (
    <View style={{ flex: 1 }}>
      {!mobileLayout && <GloriaRandomizer />}
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        contentContainerStyle={{ flex: 1 }}
      >
        <View key={"bio"} ref={(ref) => handleRef("bio", ref)}>
          <Bio
            width={width}
            height={height}
            mobileLayout={mobileLayout}
            onMoreInfo={() => setFormVisible(true)}
          />
        </View>

        <View key={"camp"} ref={(ref) => handleRef("camp", ref)}>
          <Campamento
            width={width}
            height={height}
            mobileLayout={mobileLayout}
          />
        </View>

        {false && (
          <>
            <View key={"tut"} ref={(ref) => handleRef("tut", ref)}>
              <Tutorias />
            </View>
            <View key={"cuen"} ref={(ref) => handleRef("cuen", ref)}>
              <CuentaCuentos />
            </View>
            <View key={"log"} ref={(ref) => handleRef("log", ref)}>
              <Logopedia />
            </View>
          </>
        )}
      </ScrollView>
      <TouchableOpacity
        onPress={() => setFormVisible(true)}
        style={[
          {
            position: "absolute",
            bottom: 20,
            right: 20,
            width: 110,
            height: 110,
            backgroundColor: COLOR_PALETTE.lightPurple,
            borderWidth: 5,
            borderColor: COLOR_PALETTE.purple,
            borderRadius: 200,
            alignItems: "center",
            justifyContent: "center",
          },
          {
            shadowColor: "rgba(64,64,64,1)",
            shadowOffset: {
              width: 0,
              height: 9,
            },
            shadowOpacity: 0.5,
            shadowRadius: 12.35,

            elevation: 19,
          },
        ]}
      >
        <FontAwesome name="inbox" size={27} color="rgba(0,0,0,0.7)" />
        <Text
          style={{
            fontWeight: "bold",
            fontFamily: "Mali-Bold",
            color: "rgba(0,0,0,0.7)",
          }}
        >
          Contáctame
        </Text>
      </TouchableOpacity>
      <Contacto
        visible={formVisible}
        onClose={() => setFormVisible(false)}
        mobileLayout={mobileLayout}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0)",
  },
  section: {
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    //borderBottomWidth: 1,
    //borderColor: "rgba(0,0,0,0.2)",
    marginBottom: 10,
  },
});
