import React, { useMemo } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";
import defaultValues from "../../theme/values";
import ProfeGloria from "../../components/ProfeGloria/ProfeGloria";
import { NAV_HEIGHT } from "../../components/Header/Header";
import HeroVideo from "../../components/HeroVideo/HeroVideo";
import { LinearGradient } from "expo-linear-gradient";

//const { height } = Dimensions.get("window");
export const LOGO = require("../../assets/images/logo.png");

const Bio = ({ width, height, mobileLayout, onMoreInfo }) => {
  const { fontScale } = useWindowDimensions();
  const styles = createStyles(height, mobileLayout, fontScale);
  return (
    <LinearGradient
      style={{ flex: 1 }}
      colors={[
        "white",
        COLOR_PALETTE.orange + "AA",
        COLOR_PALETTE.pink + "AA",
        COLOR_PALETTE.blue + "AA",
        COLOR_PALETTE.yellow + "AA",
        "white",
        "white",
        "transparent",
        "transparent",
        "transparent",
        "transparent",
        "transparent",
      ]}
    >
      <View
        style={{
          alignSelf: "center",
          height: "70%",
          position: "absolute",
          backgroundColor: "white",
          borderRadius: 100,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      ></View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.contentContainer}>
            {!mobileLayout && <Image source={LOGO} style={styles.logo} />}
            <View style={styles.textBox}>
              <h1 style={styles.title}>
                Donde el aprendizaje se vuelve diversión
              </h1>
              <h2>
                <Text style={styles.description} role="heading" aria-level={1}>
                  Apasionada por el desarrollo infantil, con más de seis años de
                  experiencia en Educación Inicial en República Dominicana. En
                  este espacio te comparto diferentes opciones y herramientas
                  que pueden contribuir a impulsar esos talentos y habilidades,
                  fomentando el aprendizaje significativo y experiencias de
                  calidad
                </Text>
              </h2>
            </View>
            <TouchableOpacity
              onPress={onMoreInfo}
              style={{
                padding: 20,
                paddingHorizontal: 40,
                backgroundColor: COLOR_PALETTE.lightPink,
                borderWidth: 5,
                borderColor: COLOR_PALETTE.pink,
                borderRadius: 10,

                marginBottom: 30,
              }}
            >
              <Text
                style={{
                  color: COLOR_PALETTE.font,
                  fontWeight: "bold",
                  fontFamily: "Mali-bold",
                  fontSize: 20,
                }}
              >
                ¡Más Info!
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.profeGloriaContainer}>
            {mobileLayout && <Image source={LOGO} style={styles.logo} />}
            <ProfeGloria />
          </View>
        </View>
      </View>
    </LinearGradient>
  );
};

const createStyles = (height, mobileLayout, fontScale) =>
  StyleSheet.create({
    section: {
      flex: 1,
      backgroundColor: "rgba(255,255,255,0.3)",
      alignItems: "center",
      justifyContent: "center",
    },
    row: {
      flex: 1,
      flexDirection: mobileLayout ? "column-reverse" : "row",
      minHeight: height - NAV_HEIGHT,
      alignItems: "center",
    },
    contentContainer: {
      maxWidth: defaultValues.maxWidth.primary,
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 15,
    },
    logo: {
      marginTop: mobileLayout ? 50 : 0,
      height: 150,
      width: 320,
      backgroundColor: "rgba(0,0,0,0)",
      alignSelf: "center",
      marginBottom: mobileLayout ? 20 : 0,
      tintColor: "white",
    },
    textBox: {
      marginTop: mobileLayout ? 50 : 0,
      padding: 30,
      borderRadius: 15,
      maxWidth: 1000,
      width: mobileLayout ? undefined : "80%",
    },
    title: {
      marginTop: mobileLayout ? 0 : 50,
      fontSize: mobileLayout
        ? defaultValues.fontSize.primary
        : (defaultValues.fontSize.primary * 1.5) / fontScale,
      letterSpacing: 1,
      backgroundColor: "rgba(0,0,0,0)",
      color: "#455a64",
      fontFamily: "Mouldy",
      fontWeight: "bold",
      marginBottom: 10,
    },
    description: {
      textAlign: "justify",
      fontSize: defaultValues.fontSize.body,
      letterSpacing: 1,
      color: "rgba(0,0,0,0.7)",
      fontFamily: "Mali",
      lineHeight: 30,
      backgroundColor: "white",
    },
    mark: {
      color: COLOR_PALETTE.pink,
      backgroundColor: COLOR_PALETTE.lightYellow,
      borderRadius: 10,
      paddingHorizontal: 5,
      marginTop: 5,
      marginBottom: 5,
    },
    profeGloriaContainer: {
      marginTop: 0,
      marginBottom: 40,
    },
  });

export default Bio;
