const CAMP1 = require("../../../assets/images/camp1.webp");
const CAMP2 = require("../../../assets/images/camp2.JPG");
const CAMP3 = require("../../../assets/images/camp3.JPG");
const CAMP4 = require("../../../assets/images/camp4.JPG");
const CAMP5 = require("../../../assets/images/camp5.JPG");
const CAMP6 = require("../../../assets/images/camp6.JPG");
const CAMP7 = require("../../../assets/images/camp7.JPG");
const CAMP8 = require("../../../assets/images/camp8.JPG");
const CAMP9 = require("../../../assets/images/camp9.JPG");
const GLO1 = require("../../../assets/images/glo1.png");

export const DATA = [
  {
    id: 1,
    src: CAMP1,
  },
  {
    id: 2,
    src: CAMP2,
  },
  {
    id: 3,
    src: CAMP3,
  },
  {
    id: 4,
    src: CAMP4,
  },
  {
    id: 5,
    src: CAMP5,
  },

  {
    id: 6,
    src: CAMP6,
  },
  {
    id: 7,
    src: CAMP7,
  },
  {
    id: 8,
    src: CAMP8,
  },
  {
    id: 9,
    src: CAMP9,
  },
];
