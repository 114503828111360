import {
  FlatList,
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  Dimensions,
} from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import { NAV_HEIGHT } from "../../components/Header/Header";

const { height } = Dimensions.get("window");

export default function Logopedia() {
  return (
    <View style={styles.container}>
      <View style={[styles.section]}>
        <Text
          style={{
            fontSize: 35,
            maxWidth: "50%",
            color: "rgba(0,0,0,0.7)",
            fontWeight: "bold",
            fontFamily: "Mali-Bold",
            margin: 10,
            marginBottom: 40,
          }}
        >
          ¡Próximamente: Terapias del habla y logopedia para niños!
        </Text>
        <View
          style={{
            alignSelf: "center",
            maxWidth: 800,
            padding: 60,
            backgroundColor: COLOR_PALETTE.green,
            borderRadius: 15,
          }}
        >
          <Text
            style={{
              //color: "rgba(0,0,0,0.7)",
              fontWeight: "bold",
              fontFamily: "Mali-Bold",
              fontSize: 18,
              color: "white",
              textAlign: "justify",
            }}
          >
            Estamos emocionados de anunciar que, próximamente, ampliaremos
            nuestros servicios para incluir terapias del habla y logopedia
            especializadas para niños. Nuestro objetivo es ayudar a los pequeños
            que enfrentan dificultades en la comunicación, la pronunciación y el
            lenguaje a alcanzar su máximo potencial y desarrollarse de manera
            integral.
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR_PALETTE.green + "D0",
    alignItems: "center",
    justifyContent: "center",
  },
  section: {
    //flex: 1,
    padding: 30,
    paddingVertical: 50,
    height: height - NAV_HEIGHT,
    maxWidth: 1500,
    //alignItems: "center",
    //justifyContent: "center",
    //borderBottomWidth: 1,
    //borderColor: "rgba(0,0,0,0.2)",
    marginBottom: 10,
  },
});
