const defaultValues = {
  padding: {
    small: 4,
    medium: 8,
    large: 16,
    xLarge: 32,
  },
  margin: {
    small: 4,
    medium: 8,
    large: 16,
    xLarge: 32,
  },
  maxWidth: {
    primary: 1200,
    secondary: 800,
  },
  fontSize: {
    primary: 32,
    secondary: 28,
    third: 20,
    body: 18,
    small: 12,
  },
  lineHeight: {
    body: 1.6,
    title: 1.3,
  },
  breakPoint: {
    mobile: 1200,
  },
};

export default defaultValues;
