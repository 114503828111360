import Home from "./screens/home";
import {
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  Modal,
  TouchableOpacity,
  Image,
} from "react-native";

import { FontAwesome } from "@expo/vector-icons";
import { StatusBar } from "expo-status-bar";
import { useFonts } from "expo-font";
import { useCallback, useRef, useState } from "react";
import Header from "./components/Header/Header";
import { COLOR_PALETTE } from "./theme/colors";
import defaultValues from "./theme/values";

import "./config/firebase.config";

const FLYER = require("./assets/images/flyer20232.webp");

const App = () => {
  const scrollViewRef = useRef(null);
  const [elementRefs, setElementRefs] = useState({});
  const handleRef = (key, ref) => {
    if (ref) {
      const newRef = elementRefs;
      newRef[key] = ref;
      setElementRefs(newRef);
    }
  };
  const scrollToElement = (key) => {
    if (elementRefs[key]) {
      elementRefs[key].measure((x, y, width, height, pageX, pageY) => {
        scrollViewRef?.current?.scrollTo({
          x: 0,
          y: y,
          animated: true,
        });
      });
    }
  };
  const { width, height } = useWindowDimensions();
  const [fontsLoaded] = useFonts({
    "SchoolYard-Regular": require("./assets/fonts/SchoolYard.otf"),
    Mali: require("./assets/fonts/Mali/Mali/Mali-Regular.ttf"),
    "Mali-Bold": require("./assets/fonts/Mali/Mali/Mali-Bold.ttf"),
    Mouldy: require("./assets/fonts/Mouldy/MouldyRegular.ttf"),
  });

  const [showAd, setShowAd] = useState(true);

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
    }
  }, [fontsLoaded]);

  const mobileLayout = width <= defaultValues.breakPoint.mobile;

  const onMoreInfoPressed = () => {
    setShowAd(false);
    scrollToElement("camp");
  };

  if (!fontsLoaded) {
    return null;
  }
  return (
    <>
      <Modal visible={showAd} transparent={true} animationType="fade">
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View style={{}}>
            <Image
              source={FLYER}
              resizeMode="contain"
              style={
                mobileLayout
                  ? { height: height - 30, width: width - 30 }
                  : { width: width * 0.5, height: height * 0.9 }
              }
            />

            <TouchableOpacity
              onPress={onMoreInfoPressed}
              style={{
                position: "absolute",
                width: 250,
                height: 100,
                backgroundColor: COLOR_PALETTE.purple,
                alignItems: "center",
                justifyContent: "center",
                bottom: 10,
                alignSelf: "center",
                borderRadius: 10,
                borderWidth: 5,
                borderColor: "white",
              }}
            >
              <Text
                style={{
                  fontFamily: "Mali-bold",
                  fontSize: 25,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ¡Más Info!
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setShowAd(false)}
              style={{
                position: "absolute",
                width: 50,
                height: 50,
                backgroundColor: COLOR_PALETTE.lightYellow,
                right: 0,
                top: 0,
                borderRadius: 100,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FontAwesome name="close" size={25} color="rgba(0,0,0,0.7)" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <ScrollView
        scrollEnabled={false}
        style={{ flex: 1, height: height }}
        contentContainerStyle={{ flex: 1 }}
        onLayout={onLayoutRootView}
      >
        <Header scrollToElement={scrollToElement} />
        <Home
          scrollViewRef={scrollViewRef}
          handleRef={handleRef}
          width={width}
          height={height}
          mobileLayout={mobileLayout}
        />
        <StatusBar style="auto" />
      </ScrollView>
    </>
  );
};

export default App;
