// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAV88bCWelLxMHSpeOwLc-UqPLwUb8iQ3Q",
  authDomain: "profe-gloria.firebaseapp.com",
  databaseURL: "https://profe-gloria-default-rtdb.firebaseio.com",
  projectId: "profe-gloria",
  storageBucket: "profe-gloria.appspot.com",
  messagingSenderId: "664238503112",
  appId: "1:664238503112:web:541eaa008a6bece95861fd",
  measurementId: "G-NDB6HJQLDG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { analytics, perf, db, storage };
