import React from "react";
import { StyleSheet, View, Image } from "react-native";
import CircleBackground from "./circlebackground";
import CampInfo from "./campInfo";
import ImageSlider from "./imageSlider";
import HeroVideo from "../../../components/HeroVideo/HeroVideo";

const Campamento: React.FC = ({ width, height, mobileLayout }) => {
  return (
    <View
      style={[styles.container, { minHeight: height, alignItems: "center" }]}
    >
      <HeroVideo width={width} height={height} mobileLayout={mobileLayout} />

      <View style={styles.section}>
        <CircleBackground />
        <CampInfo mobileLayout={mobileLayout} />
        <ImageSlider width={width} />
      </View>

      <View
        style={{
          width: "90%",
          height: 500,
          alignSelf: "center",
          marginBottom: 50,
        }}
      >
        <Image
          style={{ flex: 1, width: undefined, height: undefined }}
          resizeMode={"contain"}
          source={require("../../../assets/images/cupo.webp")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0)",
    marginBottom: 15,
  },
  section: {
    flex: 1,
    padding: 20,
    paddingBottom: 50,
    width: "100%",
    maxWidth: 1500,
  },
});

export default Campamento;
