import {
  FlatList,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import { COLOR_PALETTE } from "../../theme/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import { NAV_HEIGHT } from "../../components/Header/Header";

import { FontAwesome } from "@expo/vector-icons";

const TUT1 = require("../../assets/images/tuto1.JPG");
const TUT2 = require("../../assets/images/tuto2.JPG");
const TUT3 = require("../../assets/images/tuto3.JPG");

const { height } = Dimensions.get("window");

export default function Tutorias() {
  return (
    <View style={styles.container}>
      <View
        style={{
          width: 400,
          height: 400,
          borderRadius: 500,
          backgroundColor: COLOR_PALETTE.pink,
          position: "absolute",
          left: 200,
          bottom: 0,
        }}
      >
        <View
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: COLOR_PALETTE.pink,
            position: "absolute",
            top: -100,
            left: -120,
            right: 0,
          }}
        />

        <View
          style={{
            width: 50,
            height: 50,
            borderRadius: 200,
            backgroundColor: COLOR_PALETTE.pink,
            position: "absolute",
            top: 0,
            left: -150,
            right: 0,
          }}
        />
        <View
          style={{
            width: 130,
            height: 130,
            borderRadius: 200,
            backgroundColor: COLOR_PALETTE.pink,
            position: "absolute",
            top: 150,
            right: -1000,
          }}
        >
          <View
            style={{
              width: 80,
              height: 80,
              borderRadius: 200,
              backgroundColor: COLOR_PALETTE.pink,
              position: "absolute",
              top: 150,
              right: -110,
            }}
          />
          <View
            style={{
              width: 70,
              height: 70,
              borderRadius: 200,
              backgroundColor: COLOR_PALETTE.pink,
              position: "absolute",
              top: -100,
              right: -20,
            }}
          />
        </View>
      </View>
      <View style={[styles.section]}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              fontSize: 45,
              letterSpacing: 5,
              color: COLOR_PALETTE.pink,
              fontWeight: "bold",
              fontFamily: "Mouldy",
              margin: 10,
              marginBottom: 80,
            }}
          >
            Tutorias: Clases personalizadas a domicilio
          </Text>
        </View>
        <View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <ImgCardComp />
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                alignSelf: "center",
                padding: 40,
                margin: 10,
                borderRadius: 15,
                backgroundColor: "white",
                borderWidth: 5,
                borderColor: COLOR_PALETTE.pink,
              }}
            >
              <Text
                style={{
                  //color: "rgba(0,0,0,0.7)",
                  fontWeight: "bold",
                  fontFamily: "Mali-Bold",
                  fontSize: 18,
                  color: COLOR_PALETTE.pink,
                  textAlign: "justify",
                }}
              >
                En los primeros años de vida, los niños/as están en una etapa
                crucial de{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  desarrollo cognitivo, emocional y social
                </Text>
                . Es por eso que es fundamental brindarles las herramientas y el
                apoyo necesarios para ayudarlos a alcanzar su máximo potencial.
                Como tutor/a de clases personalizadas para niños/as de 2 a 6
                años, ofrezco un{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  enfoque individualizado
                </Text>{" "}
                para satisfacer las necesidades únicas de cada niño/a. Con mi
                experiencia en educación infantil, trabajo con padres y niños/as
                para identificar sus necesidades específicas y{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  adaptar mi enfoque de enseñanza
                </Text>{" "}
                para abordarlas de manera efectiva.
                {"\n"}Ya sea que tu hijo/a necesite ayuda con{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  habilidades motoras, habilidades sociales o habilidades
                  académicas
                </Text>
                , estoy aquí para brindar el{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  apoyo y el aliento necesarios
                </Text>{" "}
                para su éxito. Juntos, podemos asegurar que tu hijo/a tenga una
                base sólida para un futuro exitoso y satisfactorio. ¡Contáctame
                hoy para más información sobre mis servicios de{" "}
                <Text style={{ color: COLOR_PALETTE.purple }}>
                  clases personalizadas y tutorías
                </Text>{" "}
                para niños/as!
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const ImgCardComp = () => {
  return (
    <View style={{ marginTop: 20, flex: 1 }}>
      <View
        style={{
          position: "absolute",
          left: -30,
          top: -10,
          transform: [{ rotate: "-20deg" }],
        }}
      >
        <ImgCard title={"Martes"} img={TUT3} />
      </View>
      <View
        style={{
          position: "absolute",
          left: 220,
          top: 60,
          transform: [{ rotate: "20deg" }],
        }}
      >
        <ImgCard title={"Jueves"} img={TUT1} />
      </View>
    </View>
  );
};

const ImgCard = ({ title, img }) => {
  const { width, height } = useWindowDimensions();
  const cardWidth = width * 0.2;
  const cardHeight = cardWidth * 1.3636;
  return (
    <View
      style={[
        {
          width: cardWidth,
          height: cardHeight,
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 20,
        },
        {
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.39,
          shadowRadius: 8.3,

          elevation: 13,
        },
      ]}
    >
      <Text style={{ fontFamily: "Mali-Bold", marginBottom: 20, fontSize: 20 }}>
        {title}
      </Text>
      <View
        style={{
          backgroundColor: "gray",
          width: cardWidth * 0.9,
          height: cardWidth * 0.9,
        }}
      >
        <Image
          resizeMode="cover"
          style={{ flex: 1, width: undefined, height: undefined }}
          source={img}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgba(0,0,0,0)", //COLOR_PALETTE.pink + "D0",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  section: {
    //flex: 1,
    padding: 30,
    maxWidth: 1500,
    height: height - NAV_HEIGHT,
    alignItems: "center",
    justifyContent: "center",
    //alignItems: "center",
    //justifyContent: "center",
    //borderBottomWidth: 1,
    //borderColor: "rgba(0,0,0,0.2)",
  },
});
