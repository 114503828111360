import React, { useEffect, useRef, useState } from "react";
import { FlatList } from "react-native";
import CampCard from "./campCard";
import { DATA } from "./data";

const ImageSlider: React.FC = ({ width }) => {
  const listRef = useRef<FlatList>(null);
  const [currIndex, setCurrIndex] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newIndex = (currIndex + 1) % DATA.length;
      if (newIndex === 0) {
        setReset(true);
      }
      setCurrIndex(newIndex);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currIndex]);

  useEffect(() => {
    if (listRef.current) {
      if (reset) {
        listRef.current.scrollToIndex({ index: DATA.length, animated: true });
        setTimeout(() => {
          listRef.current.scrollToIndex({ index: 0, animated: false });
        }, 1000);
        setReset(false);
      } else {
        listRef.current.scrollToIndex({ index: currIndex, animated: true });
      }
    }
  }, [currIndex, reset]);

  return (
    <FlatList
      ref={listRef}
      style={{ flex: 1 }}
      contentContainerStyle={{ flex: 1 }}
      data={[...DATA, ...DATA]}
      horizontal
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item, index) => `${item.id}-${index}`}
      renderItem={({ item }) => <CampCard {...item} width={width} />}
    />
  );
};

export default ImageSlider;
